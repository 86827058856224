// source: stockfishai.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.hello_cargo.ChessApiGameResponse', null, global);
goog.exportSymbol('proto.hello_cargo.ChessGamesResponse', null, global);
goog.exportSymbol('proto.hello_cargo.ChessPlayer', null, global);
goog.exportSymbol('proto.hello_cargo.Game', null, global);
goog.exportSymbol('proto.hello_cargo.HelloReply', null, global);
goog.exportSymbol('proto.hello_cargo.HelloRequest', null, global);
goog.exportSymbol('proto.hello_cargo.ParsedChessGameData', null, global);
goog.exportSymbol('proto.hello_cargo.ProfileRequestData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hello_cargo.ChessGamesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hello_cargo.ChessGamesResponse.repeatedFields_, null);
};
goog.inherits(proto.hello_cargo.ChessGamesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hello_cargo.ChessGamesResponse.displayName = 'proto.hello_cargo.ChessGamesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hello_cargo.ParsedChessGameData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hello_cargo.ParsedChessGameData.repeatedFields_, null);
};
goog.inherits(proto.hello_cargo.ParsedChessGameData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hello_cargo.ParsedChessGameData.displayName = 'proto.hello_cargo.ParsedChessGameData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hello_cargo.ProfileRequestData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hello_cargo.ProfileRequestData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hello_cargo.ProfileRequestData.displayName = 'proto.hello_cargo.ProfileRequestData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hello_cargo.ChessApiGameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hello_cargo.ChessApiGameResponse.repeatedFields_, null);
};
goog.inherits(proto.hello_cargo.ChessApiGameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hello_cargo.ChessApiGameResponse.displayName = 'proto.hello_cargo.ChessApiGameResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hello_cargo.Game = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hello_cargo.Game, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hello_cargo.Game.displayName = 'proto.hello_cargo.Game';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hello_cargo.ChessPlayer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hello_cargo.ChessPlayer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hello_cargo.ChessPlayer.displayName = 'proto.hello_cargo.ChessPlayer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hello_cargo.HelloRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hello_cargo.HelloRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hello_cargo.HelloRequest.displayName = 'proto.hello_cargo.HelloRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hello_cargo.HelloReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hello_cargo.HelloReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hello_cargo.HelloReply.displayName = 'proto.hello_cargo.HelloReply';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hello_cargo.ChessGamesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hello_cargo.ChessGamesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hello_cargo.ChessGamesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hello_cargo.ChessGamesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hello_cargo.ChessGamesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamesList: jspb.Message.toObjectList(msg.getGamesList(),
    proto.hello_cargo.ParsedChessGameData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hello_cargo.ChessGamesResponse}
 */
proto.hello_cargo.ChessGamesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hello_cargo.ChessGamesResponse;
  return proto.hello_cargo.ChessGamesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hello_cargo.ChessGamesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hello_cargo.ChessGamesResponse}
 */
proto.hello_cargo.ChessGamesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hello_cargo.ParsedChessGameData;
      reader.readMessage(value,proto.hello_cargo.ParsedChessGameData.deserializeBinaryFromReader);
      msg.addGames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hello_cargo.ChessGamesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hello_cargo.ChessGamesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hello_cargo.ChessGamesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hello_cargo.ChessGamesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.hello_cargo.ParsedChessGameData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ParsedChessGameData games = 1;
 * @return {!Array<!proto.hello_cargo.ParsedChessGameData>}
 */
proto.hello_cargo.ChessGamesResponse.prototype.getGamesList = function() {
  return /** @type{!Array<!proto.hello_cargo.ParsedChessGameData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hello_cargo.ParsedChessGameData, 1));
};


/**
 * @param {!Array<!proto.hello_cargo.ParsedChessGameData>} value
 * @return {!proto.hello_cargo.ChessGamesResponse} returns this
*/
proto.hello_cargo.ChessGamesResponse.prototype.setGamesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hello_cargo.ParsedChessGameData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hello_cargo.ParsedChessGameData}
 */
proto.hello_cargo.ChessGamesResponse.prototype.addGames = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hello_cargo.ParsedChessGameData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hello_cargo.ChessGamesResponse} returns this
 */
proto.hello_cargo.ChessGamesResponse.prototype.clearGamesList = function() {
  return this.setGamesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hello_cargo.ParsedChessGameData.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hello_cargo.ParsedChessGameData.prototype.toObject = function(opt_includeInstance) {
  return proto.hello_cargo.ParsedChessGameData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hello_cargo.ParsedChessGameData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hello_cargo.ParsedChessGameData.toObject = function(includeInstance, msg) {
  var f, obj = {
    probabilitiesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f,
    lanMovesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    sanMovesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    fenValuesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    bestMovesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    white: (f = msg.getWhite()) && proto.hello_cargo.ChessPlayer.toObject(includeInstance, f),
    black: (f = msg.getBlack()) && proto.hello_cargo.ChessPlayer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hello_cargo.ParsedChessGameData}
 */
proto.hello_cargo.ParsedChessGameData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hello_cargo.ParsedChessGameData;
  return proto.hello_cargo.ParsedChessGameData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hello_cargo.ParsedChessGameData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hello_cargo.ParsedChessGameData}
 */
proto.hello_cargo.ParsedChessGameData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProbabilities(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addLanMoves(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addSanMoves(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addFenValues(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addBestMoves(value);
      break;
    case 6:
      var value = new proto.hello_cargo.ChessPlayer;
      reader.readMessage(value,proto.hello_cargo.ChessPlayer.deserializeBinaryFromReader);
      msg.setWhite(value);
      break;
    case 7:
      var value = new proto.hello_cargo.ChessPlayer;
      reader.readMessage(value,proto.hello_cargo.ChessPlayer.deserializeBinaryFromReader);
      msg.setBlack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hello_cargo.ParsedChessGameData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hello_cargo.ParsedChessGameData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hello_cargo.ParsedChessGameData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hello_cargo.ParsedChessGameData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProbabilitiesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      1,
      f
    );
  }
  f = message.getLanMovesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getSanMovesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getFenValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getBestMovesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getWhite();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.hello_cargo.ChessPlayer.serializeBinaryToWriter
    );
  }
  f = message.getBlack();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.hello_cargo.ChessPlayer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated double probabilities = 1;
 * @return {!Array<number>}
 */
proto.hello_cargo.ParsedChessGameData.prototype.getProbabilitiesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.hello_cargo.ParsedChessGameData} returns this
 */
proto.hello_cargo.ParsedChessGameData.prototype.setProbabilitiesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.hello_cargo.ParsedChessGameData} returns this
 */
proto.hello_cargo.ParsedChessGameData.prototype.addProbabilities = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hello_cargo.ParsedChessGameData} returns this
 */
proto.hello_cargo.ParsedChessGameData.prototype.clearProbabilitiesList = function() {
  return this.setProbabilitiesList([]);
};


/**
 * repeated string lan_moves = 2;
 * @return {!Array<string>}
 */
proto.hello_cargo.ParsedChessGameData.prototype.getLanMovesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hello_cargo.ParsedChessGameData} returns this
 */
proto.hello_cargo.ParsedChessGameData.prototype.setLanMovesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hello_cargo.ParsedChessGameData} returns this
 */
proto.hello_cargo.ParsedChessGameData.prototype.addLanMoves = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hello_cargo.ParsedChessGameData} returns this
 */
proto.hello_cargo.ParsedChessGameData.prototype.clearLanMovesList = function() {
  return this.setLanMovesList([]);
};


/**
 * repeated string san_moves = 3;
 * @return {!Array<string>}
 */
proto.hello_cargo.ParsedChessGameData.prototype.getSanMovesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hello_cargo.ParsedChessGameData} returns this
 */
proto.hello_cargo.ParsedChessGameData.prototype.setSanMovesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hello_cargo.ParsedChessGameData} returns this
 */
proto.hello_cargo.ParsedChessGameData.prototype.addSanMoves = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hello_cargo.ParsedChessGameData} returns this
 */
proto.hello_cargo.ParsedChessGameData.prototype.clearSanMovesList = function() {
  return this.setSanMovesList([]);
};


/**
 * repeated string fen_values = 4;
 * @return {!Array<string>}
 */
proto.hello_cargo.ParsedChessGameData.prototype.getFenValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hello_cargo.ParsedChessGameData} returns this
 */
proto.hello_cargo.ParsedChessGameData.prototype.setFenValuesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hello_cargo.ParsedChessGameData} returns this
 */
proto.hello_cargo.ParsedChessGameData.prototype.addFenValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hello_cargo.ParsedChessGameData} returns this
 */
proto.hello_cargo.ParsedChessGameData.prototype.clearFenValuesList = function() {
  return this.setFenValuesList([]);
};


/**
 * repeated string best_moves = 5;
 * @return {!Array<string>}
 */
proto.hello_cargo.ParsedChessGameData.prototype.getBestMovesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hello_cargo.ParsedChessGameData} returns this
 */
proto.hello_cargo.ParsedChessGameData.prototype.setBestMovesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hello_cargo.ParsedChessGameData} returns this
 */
proto.hello_cargo.ParsedChessGameData.prototype.addBestMoves = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hello_cargo.ParsedChessGameData} returns this
 */
proto.hello_cargo.ParsedChessGameData.prototype.clearBestMovesList = function() {
  return this.setBestMovesList([]);
};


/**
 * optional ChessPlayer white = 6;
 * @return {?proto.hello_cargo.ChessPlayer}
 */
proto.hello_cargo.ParsedChessGameData.prototype.getWhite = function() {
  return /** @type{?proto.hello_cargo.ChessPlayer} */ (
    jspb.Message.getWrapperField(this, proto.hello_cargo.ChessPlayer, 6));
};


/**
 * @param {?proto.hello_cargo.ChessPlayer|undefined} value
 * @return {!proto.hello_cargo.ParsedChessGameData} returns this
*/
proto.hello_cargo.ParsedChessGameData.prototype.setWhite = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hello_cargo.ParsedChessGameData} returns this
 */
proto.hello_cargo.ParsedChessGameData.prototype.clearWhite = function() {
  return this.setWhite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hello_cargo.ParsedChessGameData.prototype.hasWhite = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ChessPlayer black = 7;
 * @return {?proto.hello_cargo.ChessPlayer}
 */
proto.hello_cargo.ParsedChessGameData.prototype.getBlack = function() {
  return /** @type{?proto.hello_cargo.ChessPlayer} */ (
    jspb.Message.getWrapperField(this, proto.hello_cargo.ChessPlayer, 7));
};


/**
 * @param {?proto.hello_cargo.ChessPlayer|undefined} value
 * @return {!proto.hello_cargo.ParsedChessGameData} returns this
*/
proto.hello_cargo.ParsedChessGameData.prototype.setBlack = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hello_cargo.ParsedChessGameData} returns this
 */
proto.hello_cargo.ParsedChessGameData.prototype.clearBlack = function() {
  return this.setBlack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hello_cargo.ParsedChessGameData.prototype.hasBlack = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hello_cargo.ProfileRequestData.prototype.toObject = function(opt_includeInstance) {
  return proto.hello_cargo.ProfileRequestData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hello_cargo.ProfileRequestData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hello_cargo.ProfileRequestData.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    numberofgames: jspb.Message.getFieldWithDefault(msg, 2, ""),
    month: jspb.Message.getFieldWithDefault(msg, 3, ""),
    year: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hello_cargo.ProfileRequestData}
 */
proto.hello_cargo.ProfileRequestData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hello_cargo.ProfileRequestData;
  return proto.hello_cargo.ProfileRequestData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hello_cargo.ProfileRequestData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hello_cargo.ProfileRequestData}
 */
proto.hello_cargo.ProfileRequestData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumberofgames(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonth(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hello_cargo.ProfileRequestData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hello_cargo.ProfileRequestData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hello_cargo.ProfileRequestData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hello_cargo.ProfileRequestData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumberofgames();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMonth();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.hello_cargo.ProfileRequestData.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hello_cargo.ProfileRequestData} returns this
 */
proto.hello_cargo.ProfileRequestData.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string numberOfGames = 2;
 * @return {string}
 */
proto.hello_cargo.ProfileRequestData.prototype.getNumberofgames = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hello_cargo.ProfileRequestData} returns this
 */
proto.hello_cargo.ProfileRequestData.prototype.setNumberofgames = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string month = 3;
 * @return {string}
 */
proto.hello_cargo.ProfileRequestData.prototype.getMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hello_cargo.ProfileRequestData} returns this
 */
proto.hello_cargo.ProfileRequestData.prototype.setMonth = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string year = 4;
 * @return {string}
 */
proto.hello_cargo.ProfileRequestData.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hello_cargo.ProfileRequestData} returns this
 */
proto.hello_cargo.ProfileRequestData.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hello_cargo.ChessApiGameResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hello_cargo.ChessApiGameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hello_cargo.ChessApiGameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hello_cargo.ChessApiGameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hello_cargo.ChessApiGameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamesList: jspb.Message.toObjectList(msg.getGamesList(),
    proto.hello_cargo.Game.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hello_cargo.ChessApiGameResponse}
 */
proto.hello_cargo.ChessApiGameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hello_cargo.ChessApiGameResponse;
  return proto.hello_cargo.ChessApiGameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hello_cargo.ChessApiGameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hello_cargo.ChessApiGameResponse}
 */
proto.hello_cargo.ChessApiGameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hello_cargo.Game;
      reader.readMessage(value,proto.hello_cargo.Game.deserializeBinaryFromReader);
      msg.addGames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hello_cargo.ChessApiGameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hello_cargo.ChessApiGameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hello_cargo.ChessApiGameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hello_cargo.ChessApiGameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.hello_cargo.Game.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Game games = 1;
 * @return {!Array<!proto.hello_cargo.Game>}
 */
proto.hello_cargo.ChessApiGameResponse.prototype.getGamesList = function() {
  return /** @type{!Array<!proto.hello_cargo.Game>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hello_cargo.Game, 1));
};


/**
 * @param {!Array<!proto.hello_cargo.Game>} value
 * @return {!proto.hello_cargo.ChessApiGameResponse} returns this
*/
proto.hello_cargo.ChessApiGameResponse.prototype.setGamesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hello_cargo.Game=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hello_cargo.Game}
 */
proto.hello_cargo.ChessApiGameResponse.prototype.addGames = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hello_cargo.Game, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hello_cargo.ChessApiGameResponse} returns this
 */
proto.hello_cargo.ChessApiGameResponse.prototype.clearGamesList = function() {
  return this.setGamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hello_cargo.Game.prototype.toObject = function(opt_includeInstance) {
  return proto.hello_cargo.Game.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hello_cargo.Game} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hello_cargo.Game.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pgn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tcn: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fen: jspb.Message.getFieldWithDefault(msg, 4, ""),
    white: (f = msg.getWhite()) && proto.hello_cargo.ChessPlayer.toObject(includeInstance, f),
    black: (f = msg.getBlack()) && proto.hello_cargo.ChessPlayer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hello_cargo.Game}
 */
proto.hello_cargo.Game.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hello_cargo.Game;
  return proto.hello_cargo.Game.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hello_cargo.Game} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hello_cargo.Game}
 */
proto.hello_cargo.Game.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPgn(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTcn(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFen(value);
      break;
    case 5:
      var value = new proto.hello_cargo.ChessPlayer;
      reader.readMessage(value,proto.hello_cargo.ChessPlayer.deserializeBinaryFromReader);
      msg.setWhite(value);
      break;
    case 6:
      var value = new proto.hello_cargo.ChessPlayer;
      reader.readMessage(value,proto.hello_cargo.ChessPlayer.deserializeBinaryFromReader);
      msg.setBlack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hello_cargo.Game.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hello_cargo.Game.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hello_cargo.Game} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hello_cargo.Game.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPgn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTcn();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFen();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWhite();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.hello_cargo.ChessPlayer.serializeBinaryToWriter
    );
  }
  f = message.getBlack();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.hello_cargo.ChessPlayer.serializeBinaryToWriter
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.hello_cargo.Game.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hello_cargo.Game} returns this
 */
proto.hello_cargo.Game.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pgn = 2;
 * @return {string}
 */
proto.hello_cargo.Game.prototype.getPgn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hello_cargo.Game} returns this
 */
proto.hello_cargo.Game.prototype.setPgn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tcn = 3;
 * @return {string}
 */
proto.hello_cargo.Game.prototype.getTcn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hello_cargo.Game} returns this
 */
proto.hello_cargo.Game.prototype.setTcn = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string fen = 4;
 * @return {string}
 */
proto.hello_cargo.Game.prototype.getFen = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hello_cargo.Game} returns this
 */
proto.hello_cargo.Game.prototype.setFen = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional ChessPlayer white = 5;
 * @return {?proto.hello_cargo.ChessPlayer}
 */
proto.hello_cargo.Game.prototype.getWhite = function() {
  return /** @type{?proto.hello_cargo.ChessPlayer} */ (
    jspb.Message.getWrapperField(this, proto.hello_cargo.ChessPlayer, 5));
};


/**
 * @param {?proto.hello_cargo.ChessPlayer|undefined} value
 * @return {!proto.hello_cargo.Game} returns this
*/
proto.hello_cargo.Game.prototype.setWhite = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hello_cargo.Game} returns this
 */
proto.hello_cargo.Game.prototype.clearWhite = function() {
  return this.setWhite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hello_cargo.Game.prototype.hasWhite = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ChessPlayer black = 6;
 * @return {?proto.hello_cargo.ChessPlayer}
 */
proto.hello_cargo.Game.prototype.getBlack = function() {
  return /** @type{?proto.hello_cargo.ChessPlayer} */ (
    jspb.Message.getWrapperField(this, proto.hello_cargo.ChessPlayer, 6));
};


/**
 * @param {?proto.hello_cargo.ChessPlayer|undefined} value
 * @return {!proto.hello_cargo.Game} returns this
*/
proto.hello_cargo.Game.prototype.setBlack = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hello_cargo.Game} returns this
 */
proto.hello_cargo.Game.prototype.clearBlack = function() {
  return this.setBlack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hello_cargo.Game.prototype.hasBlack = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hello_cargo.ChessPlayer.prototype.toObject = function(opt_includeInstance) {
  return proto.hello_cargo.ChessPlayer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hello_cargo.ChessPlayer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hello_cargo.ChessPlayer.toObject = function(includeInstance, msg) {
  var f, obj = {
    rating: jspb.Message.getFieldWithDefault(msg, 1, 0),
    result: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hello_cargo.ChessPlayer}
 */
proto.hello_cargo.ChessPlayer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hello_cargo.ChessPlayer;
  return proto.hello_cargo.ChessPlayer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hello_cargo.ChessPlayer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hello_cargo.ChessPlayer}
 */
proto.hello_cargo.ChessPlayer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRating(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hello_cargo.ChessPlayer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hello_cargo.ChessPlayer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hello_cargo.ChessPlayer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hello_cargo.ChessPlayer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRating();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 rating = 1;
 * @return {number}
 */
proto.hello_cargo.ChessPlayer.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hello_cargo.ChessPlayer} returns this
 */
proto.hello_cargo.ChessPlayer.prototype.setRating = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string result = 2;
 * @return {string}
 */
proto.hello_cargo.ChessPlayer.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hello_cargo.ChessPlayer} returns this
 */
proto.hello_cargo.ChessPlayer.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.hello_cargo.ChessPlayer.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hello_cargo.ChessPlayer} returns this
 */
proto.hello_cargo.ChessPlayer.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hello_cargo.HelloRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hello_cargo.HelloRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hello_cargo.HelloRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hello_cargo.HelloRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hello_cargo.HelloRequest}
 */
proto.hello_cargo.HelloRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hello_cargo.HelloRequest;
  return proto.hello_cargo.HelloRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hello_cargo.HelloRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hello_cargo.HelloRequest}
 */
proto.hello_cargo.HelloRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hello_cargo.HelloRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hello_cargo.HelloRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hello_cargo.HelloRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hello_cargo.HelloRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.hello_cargo.HelloRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hello_cargo.HelloRequest} returns this
 */
proto.hello_cargo.HelloRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hello_cargo.HelloReply.prototype.toObject = function(opt_includeInstance) {
  return proto.hello_cargo.HelloReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hello_cargo.HelloReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hello_cargo.HelloReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hello_cargo.HelloReply}
 */
proto.hello_cargo.HelloReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hello_cargo.HelloReply;
  return proto.hello_cargo.HelloReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hello_cargo.HelloReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hello_cargo.HelloReply}
 */
proto.hello_cargo.HelloReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hello_cargo.HelloReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hello_cargo.HelloReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hello_cargo.HelloReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hello_cargo.HelloReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.hello_cargo.HelloReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hello_cargo.HelloReply} returns this
 */
proto.hello_cargo.HelloReply.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.hello_cargo);

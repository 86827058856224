/**
 * @fileoverview gRPC-Web generated client stub for hello_cargo
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: stockfishai.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.hello_cargo = require('./stockfishai_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.hello_cargo.GreeterClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.hello_cargo.GreeterPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hello_cargo.HelloRequest,
 *   !proto.hello_cargo.HelloReply>}
 */
const methodDescriptor_Greeter_SayHello = new grpc.web.MethodDescriptor(
  '/hello_cargo.Greeter/SayHello',
  grpc.web.MethodType.UNARY,
  proto.hello_cargo.HelloRequest,
  proto.hello_cargo.HelloReply,
  /**
   * @param {!proto.hello_cargo.HelloRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hello_cargo.HelloReply.deserializeBinary
);


/**
 * @param {!proto.hello_cargo.HelloRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hello_cargo.HelloReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hello_cargo.HelloReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hello_cargo.GreeterClient.prototype.sayHello =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hello_cargo.Greeter/SayHello',
      request,
      metadata || {},
      methodDescriptor_Greeter_SayHello,
      callback);
};


/**
 * @param {!proto.hello_cargo.HelloRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hello_cargo.HelloReply>}
 *     Promise that resolves to the response
 */
proto.hello_cargo.GreeterPromiseClient.prototype.sayHello =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hello_cargo.Greeter/SayHello',
      request,
      metadata || {},
      methodDescriptor_Greeter_SayHello);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.hello_cargo.ChessServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.hello_cargo.ChessServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.hello_cargo.ProfileRequestData,
 *   !proto.hello_cargo.ChessGamesResponse>}
 */
const methodDescriptor_ChessService_GetChessGames = new grpc.web.MethodDescriptor(
  '/hello_cargo.ChessService/GetChessGames',
  grpc.web.MethodType.UNARY,
  proto.hello_cargo.ProfileRequestData,
  proto.hello_cargo.ChessGamesResponse,
  /**
   * @param {!proto.hello_cargo.ProfileRequestData} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.hello_cargo.ChessGamesResponse.deserializeBinary
);


/**
 * @param {!proto.hello_cargo.ProfileRequestData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.hello_cargo.ChessGamesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.hello_cargo.ChessGamesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.hello_cargo.ChessServiceClient.prototype.getChessGames =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/hello_cargo.ChessService/GetChessGames',
      request,
      metadata || {},
      methodDescriptor_ChessService_GetChessGames,
      callback);
};


/**
 * @param {!proto.hello_cargo.ProfileRequestData} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.hello_cargo.ChessGamesResponse>}
 *     Promise that resolves to the response
 */
proto.hello_cargo.ChessServicePromiseClient.prototype.getChessGames =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/hello_cargo.ChessService/GetChessGames',
      request,
      metadata || {},
      methodDescriptor_ChessService_GetChessGames);
};


module.exports = proto.hello_cargo;

